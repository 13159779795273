angular.module('app')
  .controller('TutorialController', function($rootScope, $scope, $routeParams, $i18next) {
    // these are the step for confirmation IRD tutorial
    this.runIntroJs = function() {
      var status = $routeParams.status || 'PENDING';
      var introJsStep = [
        {
          // this an class for an element in features/ird/pending-grid.html
          element: '#introjs-step1',
          intro: $i18next('conf:tutorial.step1'),
          position: 'bottom'
        },
        {
          element: document.querySelectorAll('#introjs-step2')[0],
          intro: $i18next('conf:tutorial.step2'),
          position: 'left'
        },
        {
          element: '#introjs-step3',
          intro: $i18next('conf:tutorial.step3'),
          position: 'bottom'
        },
        {
          element: '#introjs-step4',
          intro: $i18next('conf:tutorial.step4'),
          position: 'bottom'
        },
        {
          element: '#introjs-step5',
          intro: $i18next('conf:tutorial.step5'),
          position: 'bottom'
        }
      ];

      // remove step2 if no lines present
      if (status === 'PENDING' && document.querySelectorAll('#introjs-step2')[0] === undefined) {
        introJsStep.splice(1, 1);
      } else if (status !== 'PENDING') {   // when pending tab is selected, adjust the steps
        // remove step 2
        introJsStep.splice(1, 1);
        // replace step 4
        introJsStep.splice(2, 1, {
          element: '#introjs-step4-bis',
          intro: $i18next('conf:tutorial.step4'),
          position: 'bottom'
        });
      }

      var intro = window.introJs();
      intro.setOption('nextLabel', $i18next('conf:tutorial.buttonNext'));
      intro.setOption('skipLabel', $i18next('conf:tutorial.buttonSkip'));
      intro.setOption('doneLabel', $i18next('conf:tutorial.buttonDone'));
      intro.setOptions({
        steps: introJsStep
      });
      intro.start();
    };
  });
