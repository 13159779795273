window.ezweb.start() //
  .config(function(ezUsersProvider, ezPostAuthenticationBackendProvider) {
    ezPostAuthenticationBackendProvider.configure({
      supportsRememberMe: false,
      currentUserUri: window.appConfig.backend_url + '/authentication/current'
    });
    ezUsersProvider.configure('ezPostAuthenticationBackend');
  })
  // Redirect when 403 forbidden
  .config(['$locationProvider', function($locationProvider) {
    $locationProvider.hashPrefix('');
  }])
  .config(function($httpProvider) {
    $httpProvider.interceptors.push(function($q, $window, $i18next) {
      return {
        responseError: function(response) {
          var status = response.status;
          if (status === 403 && response.config.method === 'GET' && response.config.url.endsWith('/api/authentication/current')) {
            const redirectionUrl = window.appConfig.unauthorized_page_redirection_base_url + "/?redirectUri=" + window.appConfig.sgConnect.redirect_uri + "&language=" + $i18next.options.lng;
            window.location.href = redirectionUrl;
          }
          return $q.reject(response);
        }
      };
    });
  })
  .filter('arrayToDate', function() {
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      return new Date(input[0], input[1] - 1, input[2], 0, 0, 0, 0);
    };
  })
  .filter('arrayToTimestamp', function() {
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      return new Date(input[0], input[1] - 1, input[2], input[3], input[4], input[5], 0);
    };
  })
  .filter('arrayToStrTimestampWithMonth', function($i18next) {
    var tabKey = {
      1: 'ezweb-form:datepicker.monthsShort.Jan',
      2: 'ezweb-form:datepicker.monthsShort.Feb',
      3: 'ezweb-form:datepicker.monthsShort.Mar',
      4: 'ezweb-form:datepicker.monthsShort.Apr',
      5: 'ezweb-form:datepicker.monthsShort.May',
      6: 'ezweb-form:datepicker.monthsShort.Jun',
      7: 'ezweb-form:datepicker.monthsShort.Jul',
      8: 'ezweb-form:datepicker.monthsShort.Aug',
      9: 'ezweb-form:datepicker.monthsShort.Sep',
      10: 'ezweb-form:datepicker.monthsShort.Oct',
      11: 'ezweb-form:datepicker.monthsShort.Nov',
      12: 'ezweb-form:datepicker.monthsShort.Dec'
    };
    var formatStr = function(digit) {
      return (digit < 10) ? '0' + digit : digit;
    };
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      var dateFromString = new Date(input[0], input[1] - 1, input[2], input[3], input[4], input[5]);
      return dateFromString.getDate() + '-' + $i18next(tabKey[dateFromString.getMonth() + 1]) + '-' + dateFromString.getFullYear() + //
        ' ' + formatStr(dateFromString.getHours()) + ':' + formatStr(dateFromString.getMinutes()) + ':' + formatStr(dateFromString.getSeconds());
    };
  })
  .filter('arrayToStrDateWithMonth', function($i18next) {
    var tabKey = {
      1: 'ezweb-form:datepicker.monthsShort.Jan',
      2: 'ezweb-form:datepicker.monthsShort.Feb',
      3: 'ezweb-form:datepicker.monthsShort.Mar',
      4: 'ezweb-form:datepicker.monthsShort.Apr',
      5: 'ezweb-form:datepicker.monthsShort.May',
      6: 'ezweb-form:datepicker.monthsShort.Jun',
      7: 'ezweb-form:datepicker.monthsShort.Jul',
      8: 'ezweb-form:datepicker.monthsShort.Aug',
      9: 'ezweb-form:datepicker.monthsShort.Sep',
      10: 'ezweb-form:datepicker.monthsShort.Oct',
      11: 'ezweb-form:datepicker.monthsShort.Nov',
      12: 'ezweb-form:datepicker.monthsShort.Dec'
    };
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      var dateFromString = new Date(input[0], input[1] - 1, input[2]);
      return dateFromString.getDate() + '-' + $i18next(tabKey[dateFromString.getMonth() + 1]) + '-' + dateFromString.getFullYear();
    };
  })
  .factory('$computeUrl', function() {
    return function(path) {
        return window.appConfig.backend_url + '/' + path;
    };
  })
  .factory('$extendedResourceService', function($computeUrl, $resource) {
    return function(path, other) {
      return $resource($computeUrl(path), other);
    };
  })
  .factory('$extendedHttpService', function($computeUrl, $http) {
    return {
      get: function(url, other) {
        return $http.get($computeUrl(url), other);
      },
      post: function(url, other) {
        return $http.post($computeUrl(url), other);
      },
      head: function(url) {
        return $http.head($computeUrl(url));
      },
      delete: function(url) {
        return $http.delete($computeUrl(url));
      }
    };
  })
  .factory('$appFileSaver', function(FileSaver, $extendedHttpService) {
    return {
      download: function(url) {
        $extendedHttpService.get(url, {responseType: 'arraybuffer'})
        .then(function (response) {
          const blob = new Blob([response.data], {type: 'application/zip'});
          const left = 'attachment; filename="';
          const dispo = response.headers('content-disposition');
          FileSaver.saveAs(blob, dispo.substring(left.length, dispo.length - '"'.length));
        });
      }
    };
  })
  .run(function($extendedHttpService, $window, ezAppScope, Conf) {
    // Get the ENV to store it in appScope
    $extendedHttpService.get('support/env').then(function(response) {
      var env = response.data;
      ezAppScope.ensure('envconfig', env);

      if (response.data.selectedUsername) {
        ezAppScope.ensure('client', {selected: response.data.selectedUsername});
      }
    });
    Conf.shouldSeeLargeRangeOfDate().then(function(shouldSeeLargeRangeOfDate) {
      ezAppScope.ensure("currentUser", {shouldSeeLargeRangeOfDate: shouldSeeLargeRangeOfDate});
    });
    // Save the type of authentication to appScope
    ezAppScope.ensure('authentification', {
      type: $window.app.authentificationType.toUpperCase(),
      logoutUri: '?action=logout'
    });
  });
