angular.module('app')
  .factory('confAssetClasses', function($q, $extendedHttpService, ezAppScope, selectedClientSrv) {
    var assetPromise = null;

    var get = function(roles, forceReload) {
      if (assetPromise && !forceReload) {
        return assetPromise;
      }
      assetPromise = $q(function(resolve, reject) {
        if (roles.indexOf('CONSULT_CONF_STATUS') < 0 ||
          (roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1 && !selectedClientSrv.get())) {
          setTimeout(function() {
            // Did it in timeout since no 'AfterviewInit' in angularjs
            resolve([]);
          }, 500);
          return;
        }

        return $extendedHttpService.get(selectedClientSrv.addToUrl('asset/findAssetClassesForUser'))
          .then(function(response) {
            resolve(response.data);
          })
          .catch(function() {
            reject([]);
          });
      });
      return assetPromise;
    };

    var resetForLogout = function() {
      assetPromise = null;
    };

    var assetToFeature = function(assetClass) {
      var enable = ezAppScope.envconfig.isQuickmatchEnabled || false;
      if (assetClass === 'FX_CASH' && enable) {
        return 'quickmatch';
      }
      return 'conf';
    };

    var assetDateRange = function(assetClass, shouldSeeLargeRangeOfDate) {
      console.log('shouldSeeLargeRangeOfDate', assetClass, shouldSeeLargeRangeOfDate);
      var startDate = new Date();
      var endDate = new Date();

      if (assetClass === 'SACS') {
        startDate.setDate(startDate.getDate() - 1);
      } else {
        if(shouldSeeLargeRangeOfDate) {
          startDate.setMonth(startDate.getMonth() - 12);
        } else {
          startDate.setMonth(startDate.getMonth() - 6);
        }        
      }
      return [startDate, endDate];
    };

    return {
      get: get,
      resetForLogout: resetForLogout,
      assetToFeature: assetToFeature,
      assetDateRange: assetDateRange
    };
  });
