angular.module('app')
    .controller('QuickRefuseModalController', function($i18next, $scope, $timeout, //
                    Conf, econfService, MatchingStatus, NotifService, appLanguages, $routeParams) {
      // variables
      var self = this;
      this.maxCommentLength = 200;
      // init refuse modal tradeReference field
      if ($scope.modalEvent && $scope.modalEvent.counterpartyContractID) {
        this.tradeReference = $scope.modalEvent.counterpartyContractID;
      } else if ($scope.modalEvent && $scope.modalEvent.inputCounterpartyContractID) {
        this.tradeReference = $scope.modalEvent.inputCounterpartyContractID;
      }
      this.hasSupportRole = ($scope.ezweb.user.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1);
      this.assetClass = $routeParams.assetClass;
      this.lastLng = appLanguages.valueToUseFromLngDetected($i18next.options.lng);

      /**
       * Submit the form for the refuse modal
       */
      this.submit = function() {
        var currentEvent = $scope.modalEvent;
        var refuseValidationType = 'REJECTION';

        console.debug('Submitting the form for refuse modal for the conf event', currentEvent.id);

        var fullCommentary = self.comment;
        if (self.nominal) {
          fullCommentary += ' +Nominal';
        }
        if (self.date) {
          fullCommentary += ' +Date';
        }
        if (self.instrument) {
          fullCommentary += ' +Instrument Type';
        }

        var objToPost = {};
        if (this.tradeReference) {
          objToPost = {
            userAdr: $scope.currentUser.username,
            sicId: currentEvent.doc.siroccoId,
            userRef: self.tradeReference,
            validationType: refuseValidationType,
            comments: fullCommentary
          };
        } else {
          objToPost = {
            userAdr: $scope.currentUser.username,
            sicId: currentEvent.doc.siroccoId,
            validationType: refuseValidationType,
            comments: fullCommentary
          };
        }
        console.debug('object to post to eConf', objToPost);

        // Saving the previous status
        var previousStatusLabel = currentEvent.matchingStatusLabel;

        // this calls parent controller (quickMatchController) to hide the refuse modal
        $scope.quickCtrl.refuseModal.hide();

        // Simulating the conf event processing
        currentEvent.matchingStatusLabel = $i18next('common:loading');

        // Calls econfService to update the conf event
        econfService.documentProcessing.save(objToPost, function(data) {
          if (data.status.substring(0, 1) === '2') {
            // if everything is OK
            // building a conf update to be updated in SGM Conf local cache
            var confUpdateVO = {
              type: objToPost.validationType,
              confId: currentEvent.id,
              assetClass: self.assetClass,
              locale: self.lastLng,
              clientRef: self.tradeReference
            };
            // Updating SGM Conf local cache
            Conf.updateConfInCache(confUpdateVO, function() {
              // Simulates the conf event processing by Xone (dirty) but Xone cache need some times
              $timeout(function() {
                MatchingStatus.setNewStatus(currentEvent, objToPost.validationType);
                NotifService.notifAndRemoveAfterDelay(NotifService.buildSuccessMessage(refuseValidationType));
                $scope.quickCtrl.updateFilters($scope.quickCtrl.confEvents);
              }, 3000);
            });
          } else {
            currentEvent.matchingStatusLabel = previousStatusLabel;
            NotifService.notifAndRemoveAfterDelay(NotifService.buildErrorMessage(refuseValidationType));
          }
        }, function() {
          currentEvent.matchingStatusLabel = previousStatusLabel;
          NotifService.notifAndRemoveAfterDelay(NotifService.buildErrorMessage(refuseValidationType));
        });
      };
    });
