'use strict';

angular.module('app')
  .factory('supportUsers', function($extendedResourceService) {
    return $extendedResourceService('support/user/:contactId', {}, {clear: {method: 'POST', params: {clear: true}}});
  })
  .factory('selectedClientSrv', function() {
    var selectedClient;

    var getSelectedClient = function() {
      return selectedClient;
    };

    var setSelectedClient = function(selected) {
      selectedClient = selected;
    };

    var addToUrl = function(url) {
      var selectedClient = getSelectedClient();
      if (selectedClient) {
        return url + (url.indexOf('?') > -1 ? '&' : '?') + 'selectedClient=' + selectedClient;
      }
      return url;
    };

    return {
      get: getSelectedClient,
      set: setSelectedClient,
      addToUrl: addToUrl
    };
  })
  .factory('supportContacts', function($extendedHttpService) {
    var findAllContacts = function(cb) {
      $extendedHttpService.get('support/contact')
        .then(function(response) {
          cb(response.data);
        });
    };
    return {
      findAllContacts: findAllContacts
    };
  });
