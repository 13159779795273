angular.module('app')
  .config(function(sgwtConnectProvider) {
    sgwtConnectProvider.use(window.sgwtConnect);
  })
  .config(function($routeProvider) {
    $routeProvider.when('/conf/:assetClass', {
      templateUrl: 'main.html',
      reloadOnSearch: false
    });
  })
  .controller('MainController', function($rootScope, $scope, $routeParams, $i18next,  //
                                          appLanguages, Conf, tutorial, $analytics, $location, econfService,
                                          ezAppScope, MatchingStatus, selectedClientSrv, $appFileSaver, FileSaver,
                                          confAssetClasses) {
    var self = this;


    this.rangeOfDate = confAssetClasses.assetDateRange($routeParams.assetClass, ezAppScope.currentUser.shouldSeeLargeRangeOfDate);

    if (!$routeParams.status) {
      $location.search({status: 'PENDING'});
    }
    this.status = $routeParams.status || 'PENDING';
    this.assetClass = $routeParams.assetClass;
    this.loadingConfs = true;
    this.showRangDateFilter = this.status === 'ALL' && $routeParams.assetClass !== 'IRD' && $routeParams.assetClass !== 'FX_CASH';
    this.pendingGrid = 'client/features/conf/' + this.assetClass.toLowerCase() + '/pending-grid.html';
    this.allGrid = 'client/features/conf/' + this.assetClass.toLowerCase() + '/all-grid.html';
    $analytics.pageTrack(this.assetClass);
    $analytics.eventTrack('Consultation', {category: 'Page', label: this.assetClass + ' page'});
    this.confs = {};

    $scope.loadingPreview = false;
    $scope.displayFullDocument = (this.assetClass !== 'SACS');
    $scope.maxCommentLength = 200;

    // variable used to show/hide counterpartyContractID column
    this.counterpartyContractIDfounded = {};
    this.counterpartyContractIDfounded[self.status] = undefined;

    // declare a var to remember which criteria was used for the last research
    this.lastLng = appLanguages.valueToUseFromLngDetected($i18next.options.lng);

    var queryConf = function() {
      var requestStatus = self.status;
      var fromDate;
      var toDate;
      if (self.showRangDateFilter && this.status !== 'PENDING') {
        fromDate = self.rangeOfDate[0].toISOString().slice(0, 10);
        toDate = self.rangeOfDate[1].toISOString().slice(0, 10);
      }
      Conf.find(self.assetClass, self.lastLng, requestStatus, fromDate, toDate, function(confs) {
        self.loadingConfs = false;
        self.counterpartyContractIDfounded[requestStatus] = confs.some(function(item) {
          return item.counterpartyContractID;
        });
        self.confs[requestStatus] = confs;

        if (!$scope.ezweb.user.tutorialViewed && $rootScope.app.tutorialviewed === undefined) {
          window.$('#tutorialModal').modal('show');
          ezAppScope.ensure('tutorialviewed', true);
          tutorial.save();
        }
      });
    };

	this.storingConfs = false;
    var queryStore = function () {
      self.storingConfs = true;
      var requestStatus = self.status;
      if (self.showRangDateFilter && this.status !== 'PENDING') {
        var fromDate = self.rangeOfDate[0].toISOString().slice(0, 10);
        var toDate = self.rangeOfDate[1].toISOString().slice(0, 10);
      }
      Conf.store(self.assetClass, self.lastLng, requestStatus, fromDate, toDate, function() {
        self.storingConfs = false;
      });
    };

	this.cleaningActions = false;
    var queryCleanUserActions = function () {
      self.cleaningActions = true;      
      Conf.deleteUserActions(function() {
        self.cleaningActions = false;
      });
    };

    // Function to load the good tab on click and not reload it when tab changed
    var load = function() {
      // $scope.lastLng can be undefined when i18next is still in loading
      if (self.lastLng && !self.confs[self.status]) {
        queryConf();
      }
    };
    // Load the active tab at startup
    load();

    this.changeTab = function(status) {
      self.status = status;
      $location.search('status', status);
      this.showRangDateFilter = this.status !== 'PENDING' && $routeParams.assetClass !== 'IRD' && $routeParams.assetClass !== 'FX_CASH';

      load();
    };

    $rootScope.$on('reloadConf', function($event, eventRow) {
      if (self.confs.PENDING && eventRow.matchingStatus === 'MATCHED') {
        self.confs.ALL = undefined;
        var index = self.confs.PENDING.indexOf(eventRow);
        if (index > -1) {
          self.confs.PENDING.splice(index, 1);
        }
      }
    });

    this.downloadPdfFile = function(event, content, siroccoId, index) {
      event.preventDefault();
      FileSaver.saveAs(new Blob([content], {type: "application/pdf"}), siroccoId + "_" + index + ".pdf");
    };

    this.pdfDownloadLink = function(pendingConfEvent, index) {
      const url = 'econf/pdfdownload/' + pendingConfEvent.doc.siroccoId + '/' + index;
      return selectedClientSrv.addToUrl(url);
    };

    /**
     * This function calls the service MatchingStatusClass to calculate the css class for the status passed.
     */
    this.findClassForStatus = function(status) {
      return MatchingStatus.findClassForStatus(status);
    };

    // We cannot use alpha sort on column 'status' so use custom values in sort filter
    this.findSortValueForStatus = function(status) {
      if (status === 'MATCHED') {
        return 'ddd';
      }
      if (status === 'MISMATCHED') {
        return 'ccc';
      }
      if (status === 'PREVALIDATED' || status === 'PRE_VALIDATED') {
        return 'bbb';
      }
      if (status === 'PENDING') {
        return 'aaa';
      }
      return status;
    };

    this.i18nStatusKey = function(status) {
      if (status === 'MATCHED') {
        return 'conf:statusMatched';
      }
      if (status === 'MISMATCHED') {
        return 'conf:statusMismatched';
      }
      if (status === 'PREVALIDATED' || status === 'PRE_VALIDATED') {
        return 'conf:statusPrevalidated';
      }
      if (status === 'PENDING') {
        return 'conf:statusPending';
      }
      return '';
    };

    this.showClient = function() {
      if ($scope.app.client !== undefined) {
        return $scope.app.client.multiEc;
      }
      return $scope.ezweb.user.multiEc;
    };

    $scope.auditTrails = [];
    $scope.openValidationModal = function(clientRef, pendingConfEvent, event) {
      $scope.pendingConfEvent = pendingConfEvent;
      $scope.clientRef = clientRef;

      this.pdfUrls = [];
      $scope.loadingDocuments = true;
      $scope.loadingDocumentsError = false;

      $scope.auditTrails = [];
      $scope.loadingAuditTrails = true;
      $scope.loadingAuditTrailsError = false;


      // building query param
      var queryParam = {siroccoId: pendingConfEvent.doc.siroccoId};
      var selectedClient = selectedClientSrv.get();
      if (selectedClient) {
        queryParam = {siroccoId: pendingConfEvent.doc.siroccoId, selectedClient: selectedClient};
      }

      econfService.auditTrails.query(queryParam, function(trails){
        $scope.auditTrails = trails;
        if ($scope.auditTrails !== null && $scope.auditTrails.length > 0) {
          $scope.validationComment = $scope.auditTrails[0].comment;
        }
        $scope.loadingAuditTrails = false;
      }.bind(this), function() {
        $scope.loadingAuditTrailsError = true;
        $scope.loadingAuditTrails = false;
      });

      econfService.documents.query(queryParam, function(documents) {
        // documents is an array of byte array representing an array of all pdf that we need to display
        $scope.documents = documents;

        this.processDocuments(documents);
        $scope.loadingDocuments = false;
      }.bind(this), function() {
        $scope.loadingDocumentsError = true;
        $scope.loadingDocuments = false;
      });

      $scope.validationModal.show(event);
    }.bind(this);

    $scope.openSacsValidationModal = function(clientRef, pendingConfEvent, type, event) {
      $scope.pendingConfEvent = pendingConfEvent;
      $scope.clientRef = pendingConfEvent.instrumentName;
      pendingConfEvent.doc = {};
      pendingConfEvent.doc.siroccoId = pendingConfEvent.instrumentName.replace(/\.[^/.]+$/, "");

      // represents an array of url data used directly by ez pdf reader to display the file
      this.pdfUrls = [];
      $scope.validationComment = "";
      $scope.loadingPreview = true;

      // building query param
      var queryParam = {docType: type, id: clientRef};
      var selectedClient = selectedClientSrv.get();
      if (selectedClient) {
        queryParam = {docType: type, id: clientRef, selectedClient: selectedClient};
      }
      econfService.sacsDocuments.query(queryParam,
        function(documents) {
          // documents is an array of byte array representing an array of all pdf that we need to display
          $scope.documents = documents;

          this.processDocuments(documents);
          $scope.loadingPreview = false;
        }.bind(this),
      function(error) {
        $scope.validationComment = "Unable to load the document, please try again.";
        $scope.loadingPreview = false;
      });

      $scope.validationModal.show(event);
    }.bind(this);

    this.isViewable = function(status) {
      if (status === 'MATCHED' || status === 'MISMATCHED') {
        return true;
      }
      return false;
    };

    this.processDocuments = function(documents) {

      function base64ToUint8Array(base64) {
        var raw = atob(base64);
        var uint8Array = new Uint8Array(raw.length);
        for (var i = 0; i < raw.length; i++) {
          uint8Array[i] = raw.charCodeAt(i);
        }
        return uint8Array;
      }

      // if array of documents is not empty
      if ($scope.documents !== null && $scope.documents.length > 0) {
        documents.forEach(function(document) {
          // push file as urldata to array
          this.pdfUrls.push(base64ToUint8Array(document.data));
        }.bind(this));
      }
    };

	this.storeToMockfactory = function(event) {
      event.preventDefault();
      queryStore();
    }

    this.cleanUserActions = function(event) {
      event.preventDefault();
      queryCleanUserActions();
    }

    this.generateStoreToMockfactoryUrl = function () {
      var url = "conf/export?assetClass=" + self.assetClass + "&locale=" + self.lastLng + "&status=" + self.status;
      if (self.showRangDateFilter) {
        var fromDate = self.rangeOfDate[0].toISOString().slice(0, 10);
        var toDate = self.rangeOfDate[1].toISOString().slice(0, 10);
        url += '&fromDate=' + fromDate + '&toDate=' + toDate;
      }
      return selectedClientSrv.addToUrl(url);
    };

    this.exportXlsFile = function(event) {
      event.preventDefault();
      var url = this.generateExportUrlAndFetchXlsFile();
      $appFileSaver.download(url);
    };

    this.generateExportUrlAndFetchXlsFile = function() {
      var url = "conf/export?assetClass=" + self.assetClass + "&locale=" + self.lastLng + "&status=" + self.status;
      if (self.showRangDateFilter) {
        var fromDate = self.rangeOfDate[0].toISOString().slice(0, 10);
        var toDate = self.rangeOfDate[1].toISOString().slice(0, 10);
        url += '&fromDate=' + fromDate + '&toDate=' + toDate;
      }
      return selectedClientSrv.addToUrl(url);
    };

    this.exportPdfFile = function(event, siroccoID, assetClass, locale, status) {
      event.preventDefault();
      var url = this.generateExportUrlAndFetchPdfFile(siroccoID, assetClass, locale, status);
      $appFileSaver.download(url);
    };

    this.generateExportUrlAndFetchPdfFile = function(siroccoID, assetClass, locale, status) {
      var url = "conf/getSiroccoDoc/" + siroccoID + "?assetClass=" + assetClass + "&locale=" + locale + "&status=" + status;
      return selectedClientSrv.addToUrl(url);
    };

    this.hasSupportRole = function() {
      return ($scope.ezweb.user.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1);
    };

    this.formatReference = function(utivalue) {
      if (utivalue != null) {
        const FXOEN = utivalue.slice(0, 6);
        const reference = utivalue.substring(7);
        const referenceWithout0 = reference.replace(/^(0*)/g, '');
        return ((FXOEN + '-' + referenceWithout0).substring(3));
      }
    };

    $scope.$on('filterConfsByDates', function(event, rangeOfDate) {
      self.rangeOfDate = rangeOfDate;
      self.loadingConfs = true;
      queryConf();
    });
  })
  .controller('DateRangeController', function($scope, $routeParams, confAssetClasses, ezAppScope) {

    this.rangeOfDate = confAssetClasses.assetDateRange($routeParams.assetClass, ezAppScope.currentUser.shouldSeeLargeRangeOfDate);

    var self = this;
    this.filterConfs = function() {
      $scope.$emit('filterConfsByDates', self.rangeOfDate);
    };
  });
