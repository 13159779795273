'use strict';

angular.module('app')
  .factory('Store', function() {
    var store = {};

    var put = function(key, value) {
      store[key] = value;
    };
    var get = function(key) {
      return store[key];
    };

    return {
      get: get,
      put: put
    };
  });
