angular.module('app')
  .factory('econfService', function($extendedResourceService, $extendedHttpService, selectedClientSrv) {
    var userRights = function(siroccoId, successCallback, errorCallback) {
      var baseUrl = 'econf/userRights?siroccoId=' + siroccoId;
      $extendedHttpService.get(selectedClientSrv.addToUrl(baseUrl))
        .then(function(response) {
          successCallback(response.data);
        }, function(error) {
          console.error('error when calling conf services', error);
          errorCallback(error)
        });
    };
    return {
      auditTrails: $extendedResourceService('econf/auditTrails', {}, {}),
      userRights: userRights,
      documentProcessing: $extendedResourceService('econf/document/processing', {}, {}),
      documents: $extendedResourceService('econf/documents/:siroccoId', {}, {}),
      pdfDownload: $extendedResourceService('econf/pdfdownload/:siroccoId/:index', {}, {}),
      document: $extendedResourceService('econf/document/:siroccoId', {}, {}),
      sacsDocuments: $extendedResourceService('econf/border/document/:id', {}, {})
    };
  });
