angular.module('app')
  .factory('adminFindGroups', function($extendedResourceService) {
    return $extendedResourceService('admin/findGroups', {}, {});
  })
  .factory('adminGroupName', function($extendedResourceService) {
    return $extendedResourceService('admin/findGroupNames', {}, {});
  })
  .factory('adminFindUsers', function($extendedResourceService) {
    return $extendedResourceService('admin/findUsers/:searchInputUser/search', {}, {});
  })
  .factory('adminCreateUser', function($extendedResourceService) {
    return $extendedResourceService('admin/createUser', {}, {});
  })
  .factory('adminEditUser', function($extendedResourceService) {
    return $extendedResourceService('admin/editUser', {}, {});
  })
  .factory('adminDeleteUser', function($extendedResourceService) {
    return $extendedResourceService('admin/deleteUser', {}, {});
  })
  .factory('adminCreateGroup', function($extendedResourceService) {
    return $extendedResourceService('admin/createGroup', {}, {});
  })
  .factory('adminEditGroup', function($extendedResourceService) {
    return $extendedResourceService('admin/editGroup', {}, {});
  })
  .factory('adminRemoveGroup', function($extendedResourceService) {
    return $extendedResourceService('admin/removeGroup/:groupName', {}, {});
  });
