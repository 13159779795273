// Declaring feature components
var layoutName = 'mainLayout';

angular.module('app')
  .config(function(ezLayoutProvider) {
    ezLayoutProvider.register(layoutName, {templateUrl: 'main.html'});
    ezLayoutProvider.setDefault(layoutName);
  })
  .controller('MainLayoutController', function($scope, $rootScope, $i18next, ezUsers, confAssetClasses, //
    $location, ezAppScope, Conf,selectedClientSrv, ezNotifications, $timeout) {
    // Navigate As
    // Push name in 'Navigating as' red bar
    if (selectedClientSrv.get()) {
      $scope.selectedContact = {
        name: selectedClientSrv.get()
      };
    }
    // Define loadAssetClasses behaviour
    var loadAssetClasses = function(forceReload) {
      confAssetClasses.get($scope.ezweb.user.roles, forceReload)
        .then(function(data) {
          $rootScope.$broadcast('userInfoReceived', data);
        }).catch(function() {
          var alertObj = {
            title: $i18next('common:error'),
            text: $i18next('common:errorRequestingData'),
            importance: 'danger'
          };
          var notification = ezNotifications.add(alertObj);
          $timeout(function() {
            ezNotifications.remove(notification.id);
          }, 20000);
          $rootScope.$broadcast('userInfoReceived', []);
        });
    };

    // Do the assetClass loading
    loadAssetClasses(false);

    // Define behaviour on navigate as
    var applySelectedClientChanged = function(selectedClient) {
      $scope.$broadcast('clearUserInfo');
      $scope.selectedContact = {
        name: selectedClient
      };
      $scope.ezweb.user.roles.push('CONSULT_CONF_STATUS');
      $location.path('/');
      Conf.shouldSeeLargeRangeOfDate().then(function(shouldSeeLargeRangeOfDate) {
        ezAppScope.ensure("currentUser", {shouldSeeLargeRangeOfDate: shouldSeeLargeRangeOfDate})
      });
      loadAssetClasses(true);
    };

    $scope.$on('selectedClientChanged', function(event, selectedClient) {
      applySelectedClientChanged(selectedClient);
    });

    $scope.stopNavigateAs = function() {
      selectedClientSrv.set(undefined);
      $scope.ezweb.user.roles.splice($scope.ezweb.user.roles.indexOf('CONSULT_CONF_STATUS'), 1);
      applySelectedClientChanged(undefined);
    };

    $rootScope.$on('expired-session', function() {
      ezUsers.considerLoggedOut(true);
    });

    $scope.currentUser = ezUsers.get();

    $scope.layoutHeader = {templateUrl: '../common/html/header.html'};

    // function to calculate "contact us" list
    $scope.getSocGenContacts = function() {
    };
  })
  .controller('LoginController', function($scope, $location, ezUsers, confAssetClasses) {
    /**
     * Function called by ez login form
     */
    $scope.handleLogin = function(params) {
      // call ezweb login
      var promise = ezUsers.login(params)
        .then(function(ezUser) {
          // refresh user perimeter with 'forceReload'
          confAssetClasses.get(ezUser.roles, true).then(function() {
            $location.path('/home');
          });
        })
        .catch(function() {
          $location.path('/home');
        });
      return promise;
    };
  })
  .controller('HeaderController', function($scope, selectedClientSrv, $i18next, $location, ezAppScope, ezUsers, ezLocalStorage, appLanguages) {
    this.navigateAsAttribute = ezUsers.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1 ? 'true' : 'false';

    this.availableValues = appLanguages.availableValues;
    this.currentLanguage = $i18next.options.lng;

    // current environment
    var envName = ezAppScope.envconfig.envname;
    this.environmentName = envName === 'production' ? undefined : envName;

    var self = this;
    this.changeLanguage = function($event) {
      var selectedLng = $event.detail.language;
      if(self.currentLanguage.toUpperCase() !== selectedLng.toUpperCase()) {
        Object.keys(self.availableValues).forEach(function(key) {
          if (selectedLng.toUpperCase() === key.toUpperCase()) {
            self.currentLanguage = key;
            ezLocalStorage.language = key;
            $location.path('/');
            window.location.reload();
            return;
          }
        });
      }
    };

    this.init = function() {
      var accountCenter = document.querySelector('#my-account-center');
      if(accountCenter && this.navigateAsAttribute) {
        accountCenter.setAttribute("navigate-as", this.navigateAsAttribute)
      }
    }

    this.startNavigateAs = function() {
      this.navigateAsModal.show();
    };
  });
