angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/admin/editGroup', {
      templateUrl: 'manageGroup.html'
    });
  })
  .controller('ManageGroupController', function($scope, $location, adminCreateGroup, adminEditGroup, adminRemoveGroup, $analytics) {
    $analytics.pageTrack('Manage Group');
    $analytics.eventTrack('Consultation', {category: 'Page', label: 'Manage Group page'});
    // Init roles
    $scope.rolesData = ['MANAGE_USERS',
      'CONSULT_OTHER_USER_PERIMETER',
      'CONSULT_CONF_STATUS',
      'CONSULT_SETTLEMENT',
      'CONSULT_SERVICES',
      'FEED_MOCKFACTORY',
      'RESET_ACTIONS',
      'SHOULD_SEE_LARGE_RANGE_OF_DATE',
      'SHOULD_USE_NEW_ECONF_API',
      'SHOULD_USE_NEW_ECONF_API_FOR_ACTIONS'
    ];

    // if a group doesn't exist then fields will be empty
    if ($scope.app.group === undefined || $scope.app.group.editedGroup === undefined) {
      $scope.group = {
        name: '',
        desc: '',
        role: {}
      };
    } else {
      // if the group exist then we display
      // group data (name, desc and roles)

      // Convert roles array to JSON format
      var tabOfRoles = $scope.app.group.editedGroup.roles.split(', ');
      var res = '{';
      for (var i = 0; i < tabOfRoles.length; i++) {
        if (i !== 0) {
          res += ',';
        }
        res += '"' + tabOfRoles[i] + '": true';
      }
      res += '}';
      $scope.group = {
        name: $scope.app.group.editedGroup.name,
        desc: $scope.app.group.editedGroup.description,
        role: JSON.parse(res)
      };
    }

    // create or update a group
    $scope.createOrUpdate = function() {
      // edit a user
      if ($scope.app.group && $scope.app.group.editedGroup) {
        adminEditGroup.save($scope.group,
          function() {
            $scope.messageEditGroupInfo = true;
          },
          function() {
            $scope.messageEditGroupError = true;
          }
        );
      } else {
        // create a group
        adminCreateGroup.save($scope.group,
          function() {
            $scope.messageCreateGroupInfo = true;
          },
          function() {
            $scope.messageCreateGroupError = true;
          }
        );
      }
    };

    // remove a group
    $scope.removeCurrentGroup = function() {
      if ($scope.app.group && $scope.app.group.editedGroup) {
        adminRemoveGroup.get({
          groupName: $scope.group.name
        },
        function() {
          $location.path('/admin');
        },
        function() {
          $scope.messageRemoveGroupError = true;
        });
      }
    };
  });
