angular.module('app')
  .config(function ($routeProvider) {
    $routeProvider.when('/admin/editUser', {
      templateUrl: 'manageUser.html'
    });
  })
  .controller('ManageUserController', function ($scope, adminCreateUser, adminEditUser, adminDeleteUser, adminGroupName, $analytics) {
    $analytics.pageTrack('Manage Users');
    $analytics.eventTrack('Consultation', {category: 'Page', label: 'Manage User page'});
    $scope.groupsData = adminGroupName.query();

    $scope.availablePrecisions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    // if a user doesn't exist then login text field and group checkboxes will be empty
    if ($scope.app.user === undefined || $scope.app.user.editedUser === undefined) {
      $scope.user = {
        login: '',
        group: {},
        autoEc: '',
        manualEc: '',
        autoLegalEntity: '',
        usedBdr: '',
        isManualBdrUsed: false,
        fxcRateDecimalPrecision: 8
      };
    } else {
      // if the user exist then we display user data

      // Convert groups array to JSON format
      var res = '{';
      if ($scope.app.user.editedUser.groups !== undefined) {
        var tabOfUserGroups = $scope.app.user.editedUser.groups.split(', ');
        for (var i = 0; i < tabOfUserGroups.length; i++) {
          if (i !== 0) {
            res += ',';
          }
          res += '"' + tabOfUserGroups[i] + '": true';
        }
      }
      res += '}';
      $scope.user = {
        login: $scope.app.user.editedUser.username,
        group: JSON.parse(res),
        autoEc: $scope.app.user.editedUser.autoEc,
        manualEc: $scope.app.user.editedUser.manualEc,
        autoLegalEntity: $scope.app.user.editedUser.autoLegalEntity,
        usedBdr: $scope.app.user.editedUser.usedBdr,
        isManualBdrUsed: $scope.app.user.editedUser.isManualBdrUsed,        
        fxcRateDecimalPrecision: $scope.app.user.editedUser.fxcRateDecimalPrecision
      };
    }

    // create or update a user
    $scope.createOrUpdate = function () {
      // edit a user
      if ($scope.app.user && $scope.app.user.editedUser) {
        adminEditUser.save($scope.user,
          function () {
            $scope.messageEditInfo = true;
          },
          function () {
            $scope.messageEditError = true;
          }
        );
      } else {
        // create a user
        adminCreateUser.save($scope.user,
          function () {
            $scope.messageCreateInfo = true;
          },
          function () {
            $scope.messageCreateError = true;
          }
        );
      }
    };

    // delete user
    $scope.deleteUser = function () {
      // delete user
      if ($scope.app.user && $scope.app.user.editedUser) {
        if (!confirm('Are you sure you want to delete user ?'))
          return;
        adminDeleteUser.delete({login: $scope.user.login},
          function () {
            $scope.messageDeleteInfo = true;
          },
          function () {
            $scope.messageDeleteError = true;
          }
        );
      }
    };
  });
