angular.module('app')
  .factory('thirdpartyFindPendingConf', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/pendingConf?assetClass=:asset&locale=:locale&email=:email', {}, {});
  })
  .factory('thirdpartyFindAllConf', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/allConf?assetClass=:asset&locale=:locale&email=:email', {}, {});
  })
  .factory('thirdpartyFindAllEvents', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/allEvents?assetClass=:asset&locale=:locale&email=:email', {}, {});
  })
  .factory('thirdpartyFindTradeById', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/tradesById?assetClass=:asset&locale=:locale&email=:email&bdrId=:bdrId&contractId=:contractId', {}, {});
  })
  .factory('thirdpartyFindAuditTrails', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/auditTrails?email=:email&sicId=:sicId', {}, {});
  })
  .factory('thirdpartyFindActors', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/actors?email=:email&sicId=:sicId', {}, {});
  })
  .factory('thirdpartyFindUserRights', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/userRights?email=:email&sicId=:sicId', {}, {});
  })
  .factory('thirdpartyBatchProcesses', function($extendedResourceService) {
    return $extendedResourceService('thirdparty/kickOffXoneFilterBatch', {}, {});
  });
