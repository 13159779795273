angular.module('app')
  .config(function ($routeProvider) {
    $routeProvider.when('/quickmatch/:assetClass', {
      templateUrl: 'quickmatch.html',
      reloadOnSearch: false
    });
  })
  .controller('QuickmatchController', function ($filter, $i18next, $rootScope, $scope, $routeParams, $timeout, //
                                                appLanguages, Conf, econfService, $location, ezNotifications, NotifService, MatchingStatus, selectedClientSrv, FileSaver, $appFileSaver) {
    var self = this;                                                  

    this.fxcRateDecimalPrecision = $scope.currentUser.fxcRateDecimalPrecision;

    if (!$routeParams.status) {
      $location.search({status: 'PENDING'});
    }

    // variables
    this.assetClass = $routeParams.assetClass;
    this.status = $routeParams.status || 'PENDING';
    this.lastLng = appLanguages.valueToUseFromLngDetected($i18next.options.lng);
    this.confEvents = [];
    this.pendingCount = 0;
    this.prevalidatedCount = 0;
    this.mismatchedCount = 0;
    this.matchedCount = 0;
    this.confs = {};
    // watched by the grid's custom filter to update on confirm/deny.
    this.sentConfirmations = 0;

    // loading
    $scope.isLoading = true;
    $scope.storingConfs = false;
    $scope.cleaningActions = false;

    // partials
    this.quickmatchGrid = 'client/features/quickmatch/' + this.assetClass.toLowerCase() + '/quickmatch-grid.html';
    this.quickmatchRefuseModal = 'client/features/quickmatch/partials/modal/refuse-modal.html';
    this.quickmatchReviewModal = 'client/features/quickmatch/partials/modal/validation-modal.html';
    this.quickmatchRightModal = 'client/features/quickmatch/partials/modal/right-modal.html';
    this.quickmatchExpiredMaturityModal = 'client/features/quickmatch/partials/modal/expired-maturity-modal.html';
    this.quickmatchRightPrevalidatedModal = 'client/features/quickmatch/partials/modal/right-prevalidated-modal.html';
    this.hasSupportRole = ($scope.ezweb.user.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1);

    /**
     * Inits the grid by :
     * calculating the count for each matchingStatus the number of confEvents,
     * and building the legs for each conf event when the conf event has legs.
     */
    this.initQuickMatchView = function (confEvents) {
      for (var i = 0, len = confEvents.length; i < len; i++) {
        // toggle on events with legs
        if (self.hasLegs(confEvents[i])) {
          self.toggleChildren(confEvents[i]);
        }
      }
      $scope.isLoading = false;
    };

    /**
     * Loads the confEvents data for the view and calls the function countByStatus to count the confEvents by status.
     */
    var loadData = function () {
      var requestStatus = self.status;
      if (self.confs[requestStatus]) {
        self.confEvents = self.confs[requestStatus];
      } else {
        Conf.find(self.assetClass, self.lastLng, requestStatus, null, null, function (confEvents) {
          self.confEvents = confEvents;
          self.confs[requestStatus] = confEvents;
          self.initQuickMatchView(self.confEvents);
        }, function () {
          $scope.isLoading = false;
        });
      }
    };

    var queryStore = function () {
      self.storingConfs = true;
      var requestStatus = self.status;
      Conf.store(self.assetClass, self.lastLng, requestStatus, null, null, function() {
        self.storingConfs = false;
      });
    };

    var queryCleanUserActions = function () {
      self.cleaningActions = true;
      Conf.deleteUserActions(function() {
        self.cleaningActions = false;
      });
    };

    // Load the active tab at startup
    loadData();

    this.changeTab = function (status) {
      // $scope.isLoading = true;
      self.status = status;
      $location.search('status', status);
      loadData();
    };

    this.downloadPdfFile = function(event, content, siroccoId, index) {
      event.preventDefault();
      FileSaver.saveAs(new Blob([content], {type : "application/pdf"}), siroccoId + "_" + index + ".pdf");
    }

    this.pdfDownloadLink = function(pendingConfEvent, index) {
      const url = 'econf/pdfdownload/' + pendingConfEvent.doc.siroccoId + '/' + index;
      return selectedClientSrv.addToUrl(url);
    }

    /**
     * Calls the service MatchingStatusClass to calculate the css class for the status passed.
     */
    this.findClassForStatus = function (status) {
      return MatchingStatus.findClassForStatus(status);
    };

    /**
     * Formats the amount provided with a anguler number filter and concats the currency to it.
     */
    this.formatAmountWithCcy = function (amount, currency) {
      var formatAmount = $filter('number')(amount, 2);
      return formatAmount + ' ' + currency;
    };

    /**
     * Retrieves the SG Buys value.
     */
    this.getSGBuysValue = function (confEvent) {
      if (confEvent) {
        if (confEvent.sgBuyNearCur && confEvent.sgBuyNear) {
          return self.formatAmountWithCcy(confEvent.sgBuyNear, confEvent.sgBuyNearCur);
        } else if (confEvent.sgBuyFarCur && confEvent.sgBuyFar) {
          return self.formatAmountWithCcy(confEvent.sgBuyFar, confEvent.sgBuyFarCur);
        }
        return $i18next('conf:na');
      }
    };

    /**
     * Retrieves the SG Buys value.
     */
    this.getSGSellsValue = function (confEvent) {
      if (confEvent) {
        if (confEvent.sgSellNearCur && confEvent.sgSellNear) {
          return self.formatAmountWithCcy(confEvent.sgSellNear, confEvent.sgSellNearCur);
        } else if (confEvent.sgSellFarCur && confEvent.sgSellFar) {
          return self.formatAmountWithCcy(confEvent.sgSellFar, confEvent.sgSellFarCur);
        }
        return $i18next('conf:na');
      }
    };

    /**
     * Retrieves the Rate column value depending on how the prices are valued (near or far).
     */
    this.getRate = function (confEvent) {
      if (confEvent) {
        if ((confEvent.sgSellNearCur || confEvent.sgBuyNearCur) && confEvent.fxPrice) {
          return confEvent.fxPrice;
        } else if ((confEvent.sgSellFarCur || confEvent.sgBuyFarCur) && confEvent.fxFarPrice) {
          return confEvent.fxFarPrice;
        }
        return $i18next('conf:na');
      }
    };

    /**
     * Returns true, product with legs, if the conf event instrumentName is FxSwap.
     */
    this.hasLegs = function (confEvent) {
      if (confEvent.instrumentName === 'FxSwap') {
        return true;
      }
      return false;
    };

    this.toggleChildren = function (confEvent) {
      confEvent.showChildren = !confEvent.showChildren;
      return confEvent.showChildren;
    };

    /**
     * Checks if a conf event has siroccoId
     */
    this.hasSiroccoId = function (confEvent) {
      if (confEvent && confEvent.doc && confEvent.doc.siroccoId) {
        return true;
      }
      return false;
    };

    /**
     * Opens the validation modal for the conf event passed.
     */
    this.openValidationModal = function (clientRef, confEvent, clickEvent) {

      $scope.auditTrails = [];
      $scope.loadingAuditTrails = true;
      $scope.loadingAuditTrailsError = false;

      this.pdfUrls = [];
      $scope.loadingDocuments = true;
      $scope.loadingDocumentsError = false;

      if (!confEvent || !confEvent.doc || !confEvent.doc.siroccoId) {
        console.error('The siroccoId is not valued for the conf event', confEvent);
      } else {
        $scope.modalEvent = confEvent;
        $scope.clientRef = clientRef;

        var queryParam = {siroccoId: confEvent.doc.siroccoId};
        var selectedClient = selectedClientSrv.get();
        if(selectedClient) {
          queryParam = {siroccoId: confEvent.doc.siroccoId, selectedClient: selectedClient};
        }

        econfService.auditTrails.query(queryParam, function (trails) {
          $scope.auditTrails = trails;
          if ($scope.auditTrails !== null && $scope.auditTrails.length > 0) {
            $scope.validationComment = $scope.auditTrails[0].comment;
          }
          $scope.loadingAuditTrails = false;
        }, function(){
          $scope.loadingAuditTrailsError = true;
          $scope.loadingAuditTrails = false;
        });
        // Give the $event object to the show() method to avoid a little bug
        // @see https://itbox-jira.fr.world.socgen/jira/browse/EZW-291


        econfService.documents.query(queryParam , function (documents) {
          // documents is an array of byte array representing an array of all pdf that we need to display
          $scope.documents = documents;
          // represents an array of url data used directly by ez pdf reader to display the file
          this.pdfUrls = [];



          // if array of documents is not empty
          if ($scope.documents !== null && $scope.documents.length > 0) {
            documents.forEach(function (document) {
              //push file as urldata to array
              this.pdfUrls.push(this.base64ToUint8Array(document.data));
            }.bind(this));
          }

          $scope.loadingDocuments = false;
        }.bind(this), function(){
          $scope.loadingDocumentsError = true;
          $scope.loadingDocuments = false;
        });



        self.validationModal.show(clickEvent);
      }
    };

    this.base64ToUint8Array = function(base64) {
      var raw = atob(base64);
      var uint8Array = new Uint8Array(raw.length);
      for (var i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
      }
      return uint8Array;
    }

    /**
     * Opens the validation modal for the conf event passed.
     */
    this.openModalForView = function (clientRef, confEvent, clickEvent) {
      if (!confEvent || !confEvent.doc || !confEvent.doc.siroccoId) {
        console.error('The siroccoId is not valued for the conf event', confEvent);
      } else {
        $scope.modalEvent = confEvent;
        $scope.clientRef = clientRef;


        var queryParam = {siroccoId: confEvent.doc.siroccoId};
        var selectedClient = selectedClientSrv.get();
        if(selectedClient) {
          queryParam = {siroccoId: confEvent.doc.siroccoId, selectedClient: selectedClient};
        }


        econfService.auditTrails.query(queryParam, function (trails) {
          $scope.auditTrails = trails;
          if ($scope.auditTrails !== null && $scope.auditTrails.length > 0) {
            $scope.validationComment = $scope.auditTrails[0].comment;
          }
        });
        // Give the $event object to the show() method to avoid a little bug
        // @see https://itbox-jira.fr.world.socgen/jira/browse/EZW-291


        econfService.documents.query(queryParam, function (documents) {
          // documents is an array of byte array representing an array of all pdf that we need to display
          $scope.documents = documents;
          // represents an array of url data used directly by ez pdf reader to display the file
          this.pdfUrls = [];


          // if array of documents is not empty
          if ($scope.documents !== null && $scope.documents.length > 0) {
            documents.forEach(function (document) {
              //push file as urldata to array
              this.pdfUrls.push(this.base64ToUint8Array(document.data));
            }.bind(this));

          }
        }.bind(this));



        self.validationModal.show(clickEvent);
      }
    };

    /**
     * Opens the refuse modal for the conf event passed.
     */
    this.openRefuseModal = function (confEvent, clickEvent) {
      if (!confEvent || !confEvent.doc || !confEvent.doc.siroccoId) {
        console.error('The siroccoId is not valued for the conf event', confEvent);
      } else {
        econfService.userRights(confEvent.doc.siroccoId, function (userRights) {
          if (userRights && userRights.canRefuse) {
            $scope.modalEvent = confEvent;
            self.refuseModal.show(clickEvent);
          } else {
            self.rightModal.show(clickEvent);
          }
        });
      }
    };

    /**
     * Calls eConf to update a conf event
     */
    this.updateConfEvent = function (confEvent, validationType) {
      var objToPost = {
        userAdr: $scope.currentUser.username,
        sicId: confEvent.doc.siroccoId,
        validationType: validationType
      };

      if (confEvent.counterpartyContractID) {
        objToPost.userRef = confEvent.counterpartyContractID;
      } else if (confEvent.inputCounterpartyContractID) {
        objToPost.userRef = confEvent.inputCounterpartyContractID;
      }
      console.debug('object to post to eConf', objToPost);

      // Saving the previous status
      var previousStatusLabel = confEvent.matchingStatusLabel;

      // Simulating the conf event processing
      confEvent.matchingStatusLabel = $i18next('common:loading');

      // Calls econfService to update the conf event
      econfService.documentProcessing.save(objToPost, function (data) {
        if (data.status.substring(0, 1) === '2') {
          // if everything is OK
          // building a conf update to be updated in SGM Conf local cache
          var confUpdateVO = {
            type: objToPost.validationType,
            confId: confEvent.id,
            assetClass: self.assetClass,
            locale: self.lastLng,
            clientRef: self.tradeReference
          };
          // Updating SGM Conf local cache
          Conf.updateConfInCache(confUpdateVO, function () {
            // Simulates the conf event processing by Xone (dirty) but Xone cache need some times
            $timeout(function () {
              MatchingStatus.setNewStatus(confEvent, objToPost.validationType);
              NotifService.notifAndRemoveAfterDelay(NotifService.buildSuccessMessage(validationType));
              // self.updateFilters(self.confEvents);
            }, 3000);
          });
        } else {
          confEvent.matchingStatusLabel = previousStatusLabel;
          NotifService.notifAndRemoveAfterDelay(NotifService.buildErrorMessage(validationType));
        }
      }, function () {
        confEvent.matchingStatusLabel = previousStatusLabel;
        NotifService.notifAndRemoveAfterDelay(NotifService.buildErrorMessage(validationType));
      });
    };

    /**
     * Accepts the conf event.
     */
    this.accept = function (confEvent, clickEvent) {
      // checks user rights on the conf event
      econfService.userRights(confEvent.doc.siroccoId, function (userRights) {
        if (userRights && userRights.canValidate) {
          console.debug('Validating the conf event', confEvent.id);
          self.updateConfEvent(confEvent, 'VALIDATION');
        } else if (userRights && confEvent.matchingStatus === 'PRE_VALIDATED' && !userRights.canValidate) {
          // Prevalidator tries to prevalidate already prevalidated conf
          self.rightPrevalidatedModal.show(clickEvent);
        } else if (userRights && userRights.canPrevalidate) {
          console.debug('Prevalidating the conf event', confEvent.id);
          self.updateConfEvent(confEvent, 'PREVALIDATION');
        } else {
          var today = new Date();
          today.setHours(0, 0, 0, 0);
            console.error('The user ' + $scope.currentUser.username + ' can not validate or prevalidate the event', confEvent.id);
          if (confEvent.maturityDate && confEvent.maturityDate.getTime() <= today.getTime()) {
            self.expiredMaturityModal.show(clickEvent);
          } else {
            self.rightModal.show(clickEvent);
          }
        }
      });
    };

    /**
     * Returns true if the event's status is PENDING or PRE_VALIDATED
     */
    this.isEditable = function (status) {
      return (status === 'PENDING' || status === 'PRE_VALIDATED');
    };

    /**
     * Listens to the key pressed and update the grid if the keyboard "enter" is pressed.
     */
    this.listenKeyEnter = function (confEvent, keyboardEvent) {
      // if the key pressed is "enter"
      if (keyboardEvent && keyboardEvent.type === "keyup" && keyboardEvent.keyCode === 13) {
        // TODO : calls XONE service to update the conf event with the user input
        confEvent.counterpartyContractID = confEvent.inputCounterpartyContractID;
      }
    };

    this.storeToMockfactory = function(event) {
      event.preventDefault();
      queryStore();
    }

    this.cleanUserActions = function(event) {
      event.preventDefault();
      queryCleanUserActions();
    }

    this.exportXlsFile = function(event) {
      event.preventDefault();
      var url = this.generateExportUrlAndFetchXlsFile();
      $appFileSaver.download(url);
    }

    this.generateExportUrlAndFetchXlsFile = function () {
      var url = "conf/export?assetClass=" + self.assetClass + "&locale=" + self.lastLng + "&status=" + self.status;
      return selectedClientSrv.addToUrl(url);
    };

    this.exportPdfFile = function(event, siroccoID, assetClass, locale, status) {
      event.preventDefault();
      var url = this.generateExportUrlAndFetchPdfFile(siroccoID, assetClass, locale, status);
      $appFileSaver.download(url);
    }

    this.generateExportUrlAndFetchPdfFile = function (siroccoID, assetClass, locale, status) {
      var url = "conf/getSiroccoDoc/" + siroccoID + "?assetClass=" + assetClass + "&locale=" + locale + "&status=" + status;
      return selectedClientSrv.addToUrl(url);
    };

    this.showClient = function () {
      if ($scope.app.client !== undefined) {
        return $scope.app.client.multiEc;
      }
      return $scope.ezweb.user.multiEc;
    };
  });
