angular.module('app')
  .controller('QuickTutorialController', function($rootScope, $scope, $routeParams, $i18next) {
    // these are the step for quickmatch confirmation tutorial
    this.runIntroJs = function() {
      var introJsStep = [
        {
          element: '#quickFilters',
          intro: $i18next('conf:tutorial.quickStep1'),
          position: 'bottom'
        },
        {
          element: '#filterMATCHED',
          intro: $i18next('conf:tutorial.quickStep2'),
          position: 'bottom'
        }
      ];

      console.log('document.querySelector(".quickmatch-events")', document.querySelector('.quickmatch-events'));

      // if any pending, meaning there is a the grid, we add step for the grid.
      if ($scope.quickCtrl.pendingCount > 0) {
        // add step for action if any
        var elementPencil = document.querySelector('.confEventActions');
        if (elementPencil) {
          introJsStep.push(
            {
              element: elementPencil,
              intro: $i18next('conf:tutorial.quickStep3'),
              position: 'left'
            }
          );
        }

        // add steps for excel
        introJsStep.push(
          {
            element: '#quickMatchExport',
            intro: $i18next('conf:tutorial.step4'),
            position: 'left'
          }
        );
      }

      // add final step
      introJsStep.push({
        intro: $i18next('conf:tutorial.step5')
      });

      var intro = window.introJs();
      intro.setOption('nextLabel', $i18next('conf:tutorial.buttonNext'));
      intro.setOption('skipLabel', $i18next('conf:tutorial.buttonSkip'));
      intro.setOption('doneLabel', $i18next('conf:tutorial.buttonDone'));
      intro.setOptions({
        steps: introJsStep
      });
      intro.start();
    };
  });
