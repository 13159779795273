angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/support', {
      templateUrl: 'support.html'
    });
  })
  // TODO remove support feature (has been replaced by navigate as popup)
  .controller('SupportController', function($scope, $rootScope, ezAppScope, supportUsers, versioning, confAssetClasses) {
    $scope.version = versioning.get();

    $scope.searchInputContact = '';
    $scope.contactsDatas = [];

    $scope.search = function() {
      $scope.contactsDatas = supportUsers.query({
        searchInputContact: $scope.searchInputContact
      });
    };

    $scope.goTo = function(contact) {
      $scope.selectedContact = supportUsers.get({
        contactId: contact.username
      }, function(contactData) {
        ezAppScope.ensure('client', {
          selected: contactData.username,
          multiEc: contactData.multiEc
        });
        confAssetClasses.get(function(data) {
          $rootScope.$broadcast('NavigateAsInfo', data);
        });
      });
    };

    $scope.$on('ezweb.logout', function() {
      // Remove the selected contact name from appScope.
      ezAppScope.ensure('client', {
        selected: null
      });
    });
  });
