angular.module('app').directive('helpslider', function() {
  return {
    restrict: 'EA',
    replace: false,
    templateUrl: 'helpslider.html',
    scope: true,
    controllerAs: 'helpCtrl',
    controller: function($rootScope, $scope, $i18next) {
      this.carouselApi = null;
      this.control = {
        interval: 8000
      };

      this.slides = [
        {
          image: $i18next('help:image1'),
          title: $i18next('help:title1'),
          text: $i18next('help:text1')
        },
        {
          image: $i18next('help:image2'),
          title: $i18next('help:title2'),
          text: $i18next('help:text2')
        },
        {
          image: $i18next('help:image3'),
          title: $i18next('help:title3'),
          text: $i18next('help:text3')
        },
        {
          image: $i18next('help:image4a'),
          title: $i18next('help:title4a'),
          text: $i18next('help:text4a')
        },
        {
          image: $i18next('help:image4b'),
          title: $i18next('help:title4b'),
          text: $i18next('help:text4b')
        },
        {
          image: $i18next('help:image5'),
          title: $i18next('help:title5'),
          text: $i18next('help:text5')
        },
        {
          image: $i18next('help:image6'),
          title: $i18next('help:title6'),
          text: $i18next('help:text6')
        },
        {
          image: $i18next('help:image7'),
          title: $i18next('help:title7'),
          text: $i18next('help:text7')
        }
      ];

      $scope.$watch(function() {
        return this.carouselApi;
      }.bind(this), function(carouselApi) {
        if (carouselApi !== null) {
        }
      }.bind(this));

      this.resume = function() {
        this.carouselApi.resume();
      };

      this.pause = function() {
        this.carouselApi.pause();
      };
    }
  };
});
