angular.module('app')
.controller('QuickValidationController', function($rootScope, $scope, $timeout, $i18next, appLanguages, //
    $routeParams, econfService, ezNotifications, Conf, MatchingStatus, NotifService) {
  // variables
  var self = this;
  this.maxCommentLength = 200;
  this.assetClass = $routeParams.assetClass;
  this.lastLng = appLanguages.valueToUseFromLngDetected($i18next.options.lng);
  this.validationOptions = [{label: $i18next('conf:selectOption'), value: 'NONE'}];

  $scope.loadingUserRights = true;
  $scope.loadingUserRightsError = false;


  econfService.userRights($scope.modalEvent.doc.siroccoId, function(userRights) {
    $scope.userRights = userRights;
    if (userRights.canPrevalidate) {
      self.validationOptions.push({label: $i18next('conf:acceptTradePrevalidation'), value: 'PREVALIDATION'});
    }
    if (userRights.canValidate) {
      self.validationOptions.push({label: $i18next('conf:acceptTrade'), value: 'VALIDATION'});
    }
    if (userRights.canRefuse) {
      self.validationOptions.push({label: $i18next('conf:rejectTrade'), value: 'REJECTION'});
    }
    self.hasSupportRole = ($scope.ezweb.user.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1);

    $scope.loadingUserRights = false;
  }, function(error) {
    $scope.loadingUserRightsError = true;
    $scope.loadingUserRights = false;
  });

  this.validationType = this.validationOptions[0];

  this.submit = function() {
    var currentRow = $scope.modalEvent;
    var fullCommentary = self.comment;
    if (self.nominal) {
      fullCommentary += ' +Nominal';
    }
    if (self.date) {
      fullCommentary += ' +Date';
    }
    if (self.instrument) {
      fullCommentary += ' +Instrument Type';
    }
    var objToPost = {
      userAdr: $scope.currentUser.username,
      sicId: currentRow.doc.siroccoId,
      comments: fullCommentary,
      userRef: self.tradeReference,
      validationType: self.validationType.value
    };
    var msgKeyPrefix = 'conf:review.' + objToPost.validationType;
    var alertMsgOk = {
      title: $i18next(msgKeyPrefix + '.successTitle'),
      text: $i18next(msgKeyPrefix + '.successText'),
      importance: 'success'
    };
    var alertMsgKo = {
      title: $i18next(msgKeyPrefix + '.errorTitle'),
      text: $i18next(msgKeyPrefix + '.errorText'),
      importance: 'danger'
    };
    var backup = currentRow.matchingStatusLabel;
    currentRow.matchingStatusLabel = $i18next('common:loading');

    // this calls parent controller (quickMatchController) to hide the validation modal
    $scope.quickCtrl.validationModal.hide();

    econfService.documentProcessing.save(objToPost, function(data) {
      if (data.status.substring(0, 1) === '2') {
        var confUpdateVO = {
          type: objToPost.validationType,
          confId: currentRow.id,
          assetClass: self.assetClass,
          locale: self.lastLng,
          clientRef: self.tradeReference
        };
        Conf.updateConfInCache(confUpdateVO, function() {
          $timeout(function() { // dirty but Xone cache need some times
            MatchingStatus.setNewStatus(currentRow, objToPost.validationType);
            NotifService.notifAndRemoveAfterDelay(alertMsgOk);
            $scope.quickCtrl.updateFilters($scope.quickCtrl.confEvents);
          }, 3000);
        });
      } else {
        currentRow.matchingStatusLabel = backup;
        NotifService.notifAndRemoveAfterDelay(alertMsgKo);
      }
    }, function() {
      currentRow.matchingStatusLabel = backup;
      NotifService.notifAndRemoveAfterDelay(alertMsgKo);
    });
  };
});
