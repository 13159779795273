angular.module('app')
  .directive('navigateContacts', function() {
    return {
      restrict: 'EA',
      replace: false,
      templateUrl: 'navigateContacts.html',
      scope: true,
      controllerAs: 'navCtrl',
      controller: function($scope, supportContacts, selectedClientSrv) {
        var self = this;
        var noPrevent = false;
        this.contacts = [];

        supportContacts.findAllContacts(function(data) {
          self.contacts = data;
        });

        this.prevent = function($event) {
          if (noPrevent) {
            noPrevent = false;
          } else {
            $event.stopPropagation();
          }
        };

        this.selectContact = function(contact) {
          selectedClientSrv.set(contact.username);
          $scope.$emit('selectedClientChanged', contact.username);
          noPrevent = true;
        };
      }
    };
  });
