angular.module('app')
.controller('ValidationController', function($rootScope, $scope, $timeout, $i18next, appLanguages, //
    $routeParams, econfService, ezNotifications, Conf) {
  var self = this;
  this.assetClass = $routeParams.assetClass;
  this.lastLng = appLanguages.valueToUseFromLngDetected($i18next.options.lng);
  this.validationOptions = [{label: $i18next('conf:selectOption'), value: 'NONE'}];

  $scope.loadingUserRights = true;
  $scope.loadingUserRightsError = false;

  // retrieves rights form eConf
  econfService.userRights($scope.pendingConfEvent.doc.siroccoId, function(userRights) {
    $scope.userRights = userRights;
    if (userRights.canPrevalidate) {
      self.validationOptions.push({label: $i18next('conf:acceptTradePrevalidation'), value: 'PREVALIDATION'});
    }
    if (userRights.canValidate) {
      self.validationOptions.push({label: $i18next('conf:acceptTrade'), value: 'VALIDATION'});
    }
    if (userRights.canRefuse) {
      self.validationOptions.push({label: $i18next('conf:rejectTrade'), value: 'REJECTION'});
    }
    self.hasSupportRole = ($scope.ezweb.user.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1);


    $scope.loadingUserRights = false;
  }, function(error) {
    $scope.loadingUserRightsError = true;
    $scope.loadingUserRights = false;
  });



  this.validationType = this.validationOptions[0];

  var setNewStatus = function(row, action) {
    if (action === 'REJECTION') {
      row.matchingStatus = 'MISMATCHED';
      row.matchingStatusLabel = $i18next('conf:statusMismatched');
    } else if (action === 'PREVALIDATION') {
      row.matchingStatus = 'PREVALIDATED';
      row.matchingStatusLabel = $i18next('conf:statusPrevalidated');
    } else {
      row.matchingStatus = 'MATCHED';
      row.matchingStatusLabel = $i18next('conf:statusMatched');
    }
  };

  var notifAndRemoveAfterDelay = function(alertObj) {
    var notification = ezNotifications.add(alertObj);
    $timeout(function() {
      ezNotifications.remove(notification.id);
    }, 20000);
  };

  this.submit = function() {
    var currentRow = $scope.pendingConfEvent;
    var fullCommentary = self.comment;
    if (self.nominal) {
      fullCommentary += ' +Nominal';
    }
    if (self.date) {
      fullCommentary += ' +Date';
    }
    if (self.instrument) {
      fullCommentary += ' +Instrument Type';
    }
    var objToPost = {
      userAdr: $scope.currentUser.username,
      sicId: currentRow.doc.siroccoId,
      comments: fullCommentary,
      userRef: self.tradeReference,
      validationType: self.validationType.value
    };
    var msgKeyPrefix = 'conf:review.' + objToPost.validationType;
    var alertMsgOk = {
      title: $i18next(msgKeyPrefix + '.successTitle'),
      text: $i18next(msgKeyPrefix + '.successText'),
      importance: 'success'
    };
    var alertMsgKo = {
      title: $i18next(msgKeyPrefix + '.errorTitle'),
      text: $i18next(msgKeyPrefix + '.errorText'),
      importance: 'danger'
    };
    var backup = currentRow.matchingStatusLabel;
    currentRow.matchingStatusLabel = $i18next('common:loading');
    $scope.validationModal.hide();
    econfService.documentProcessing.save(objToPost, function(data) {
      if (data.status.substring(0, 1) === '2') {
        var confUpdateVO = {
          type: objToPost.validationType,
          confId: currentRow.id,
          assetClass: self.assetClass,
          locale: self.lastLng,
          clientRef: self.tradeReference
        };
        Conf.updateConfInCache(confUpdateVO, function() {
          $timeout(function() { // dirty but Xone cache need some times
            setNewStatus(currentRow, objToPost.validationType);
            notifAndRemoveAfterDelay(alertMsgOk);
            $rootScope.$emit('reloadConf', currentRow);
          }, 3000);
        });
      } else {
        currentRow.matchingStatusLabel = backup;
        notifAndRemoveAfterDelay(alertMsgKo);
      }
    }, function() {
      currentRow.matchingStatusLabel = backup;
      notifAndRemoveAfterDelay(alertMsgKo);
    });
  };
});
