angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/thirdparty', {
      templateUrl: 'thirdparty-all-conf.html'
    });
    $routeProvider.when('/thirdparty-all-conf', {
      templateUrl: 'thirdparty-all-conf.html'
    });
    $routeProvider.when('/thirdparty-pending-conf', {
      templateUrl: 'thirdparty-pending-conf.html'
    });
    $routeProvider.when('/thirdparty-all-events', {
      templateUrl: 'thirdparty-all-events.html'
    });
    $routeProvider.when('/thirdparty-actors', {
      templateUrl: 'thirdparty-actors.html'
    });
    $routeProvider.when('/thirdparty-audit-trails', {
      templateUrl: 'thirdparty-audit-trails.html'
    });
    $routeProvider.when('/thirdparty-trades-by-id', {
      templateUrl: 'thirdparty-trades-by-id.html'
    });
    $routeProvider.when('/thirdparty-user-rights', {
      templateUrl: 'thirdparty-user-rights.html'
    });
    $routeProvider.when('/thirdparty-batch-processes', {
      templateUrl: 'thirdparty-batch-processes.html'
    });
  })

  .controller('ThirdpartyController', function($scope, thirdpartyFindPendingConf, thirdpartyFindAllConf, thirdpartyFindAllEvents, thirdpartyFindTradeById, thirdpartyFindAuditTrails, thirdpartyFindActors, thirdpartyFindUserRights, thirdpartyBatchProcesses) {
    $scope.availableLocales = ['en', 'fr'];
    $scope.availableAssets = ['IRD', 'FX_CASH', 'FX_OPTION', 'CTY', 'EQY', 'MM', 'SACS'];

    /**
     * pending confirmations
     */
    $scope.searchPendingConfEmail = '';
    $scope.searchPendingConfLocale = $scope.availableLocales[0];
    $scope.searchPendingConfAsset = $scope.availableAssets[0];
    $scope.pendingConfirmationResponse = [];
    $scope.searchPendingConf = function() {
      $scope.pendingConfirmationResponse = thirdpartyFindPendingConf.get({
        email: $scope.searchPendingConfEmail,
        locale: $scope.searchPendingConfLocale,
        asset: $scope.searchPendingConfAsset
      });
    };

    /**
     * all confirmations
     */
    $scope.searchAllConfEmail = '';
    $scope.searchAllConfLocale = $scope.availableLocales[0];
    $scope.searchAllConfAsset = $scope.availableAssets[0];
    $scope.allConfirmationResponse = [];
    $scope.searchAllConf = function() {
      $scope.allConfirmationResponse = thirdpartyFindAllConf.get({
        email: $scope.searchAllConfEmail,
        locale: $scope.searchAllConfLocale,
        asset: $scope.searchAllConfAsset
      });
    };

    /**
     * all events
     */
    $scope.availableAllEventsAssets = ['IRD', 'FX_CASH'];
    $scope.searchAllEventsEmail = '';
    $scope.searchAllEventsLocale = $scope.availableLocales[0];
    $scope.searchAllEventsAsset = $scope.availableAllEventsAssets[0];
    $scope.allEventsResponse = [];
    $scope.searchAllEvents = function() {
      $scope.allEventsResponse = thirdpartyFindAllEvents.get({
        email: $scope.searchAllEventsEmail,
        locale: $scope.searchAllEventsLocale,
        asset: $scope.searchAllEventsAsset
      });
    };

    /**
     * trade by id
     */
    $scope.searchTradeByIdEmail = '';
    $scope.searchTradeByIdLocale = $scope.availableLocales[0];
    $scope.searchTradeByIdAsset = $scope.availableAssets[0];
    $scope.searchTradeByIdBdr = '';
    $scope.searchTradeByIdContractId = '';
    $scope.TradeByIdirmationResponse = [];
    $scope.searchTradeById = function() {
      $scope.tradeByIdResponse = thirdpartyFindTradeById.get({
        email: $scope.searchTradeByIdEmail,
        locale: $scope.searchTradeByIdLocale,
        asset: $scope.searchTradeByIdAsset,
        bdrId: $scope.searchTradeByIdBdr,
        contractId: $scope.searchTradeByIdContractId
      });
    };

    /**
     * audit trails
     */
    $scope.searchAuditTrailsEmail = '';
    $scope.searchAuditTrailsSicId = '';
    $scope.auditTrailsResponse = [];
    $scope.searchAuditTrails = function() {
      $scope.auditTrailsResponse = thirdpartyFindAuditTrails.get({
        email: $scope.searchAuditTrailsEmail,
        sicId: $scope.searchAuditTrailsSicId
      });
    };

    /**
     * Actors
     */
    $scope.searchActorsEmail = '';
    $scope.searchActorsSicId = '';
    $scope.actorsResponse = [];
    $scope.searchActors = function() {
      $scope.actorsResponse = thirdpartyFindActors.get({
        email: $scope.searchActorsEmail,
        sicId: $scope.searchActorsSicId
      });
    };

    /**
     * User Rights
     */
    $scope.searchUserRightsEmail = '';
    $scope.searchUserRightsSicId = '';
    $scope.userRightsResponse = [];
    $scope.searchUserRights = function() {
      $scope.userRightsResponse = thirdpartyFindUserRights.get({
        email: $scope.searchUserRightsEmail,
        sicId: $scope.searchUserRightsSicId
      });
    };

    /**
     * Batch Processes
     */
    $scope.xoneFilterBatchOngoingProcess = false;
    $scope.xoneFilterBatchAlreadyLaunched = false;
    $scope.kickOffXoneFiltersBatch = function() {
      $scope.xoneFilterBatchOngoingProcess = true;
      $scope.xoneFilterBatchAlreadyLaunched = false;
      thirdpartyBatchProcesses.save({},
        function() {
          $scope.xoneFilterBatchOngoingProcess = false;
        },
        function() {
          $scope.xoneFilterBatchOngoingProcess = false;
          $scope.xoneFilterBatchAlreadyLaunched = true;
        }
      );
    };

    /**
     * copy/paste to clipboard
     */
    $scope.copyToClipboard = function(responseToCopy) {
      var textToCopy = JSON.stringify(responseToCopy, null, '  ');

      // create temp element
      var copyElement = document.createElement("textarea");
      copyElement.appendChild(document.createTextNode(textToCopy));
      copyElement.id = 'tempCopyToClipboard';
      angular.element(document.body.append(copyElement));

      // select the text
      var range = document.createRange();
      range.selectNode(copyElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      // copy & cleanup
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      copyElement.remove();
    };
  });
