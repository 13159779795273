angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/admin', {
      templateUrl: 'admin.html'
    });
  })
  .controller('AdminController', function($scope, ezAppScope, adminFindUsers, adminFindGroups, $location, $analytics) {
    $analytics.pageTrack('Admin');
    $analytics.eventTrack('Consultation', {category: 'Page', label: 'Admin page'});

    // Init vars
    $scope.searchInputUser = '';
    $scope.usersData = [];

    // create new user
    $scope.createUser = function() {
      ezAppScope.ensure('user', {
        editedUser: undefined
      });
      $location.path('/admin/editUser');
    };

    // edit user
    $scope.editUser = function(user) {
      ezAppScope.ensure('user', {
        editedUser: user
      });
      $location.path('/admin/editUser');
    };

    // create new group
    $scope.createGroup = function() {
      ezAppScope.ensure('group', {
        editedGroup: undefined
      });
      $location.path('/admin/editGroup');
    };

    // edit group
    $scope.editGroup = function(group) {
      ezAppScope.ensure('group', {
        editedGroup: group
      });
      $location.path('/admin/editGroup');
    };

    // search method
    $scope.search = function() {
      $scope.usersData = adminFindUsers.query({
        searchInputUser: $scope.searchInputUser
      });
    };

    // get all groups
    $scope.groupsData = adminFindGroups.query();
  });
