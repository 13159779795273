angular.module('app')
  .factory('confContact', function($extendedHttpService) {
    var findContacts = function(locale, callback) {
      $extendedHttpService.get('conf/findContacts?locale=' + locale)
        .then(function(response) {
          callback(response.data);
        });
    };

    return {
      findContacts: findContacts
    };
  })
  .factory('Conf', function($extendedHttpService, selectedClientSrv) {
    var find = function(assetClass, locale, status, fromDate, toDate, callback) {
      var baseUrl = 'conf?assetClass=' + assetClass + '&locale=' + locale + '&status=' + status;
      if (fromDate && toDate) {
        baseUrl += '&fromDate=' + fromDate + '&toDate=' + toDate;
      }
      $extendedHttpService.get(selectedClientSrv.addToUrl(baseUrl))
        .then(function(response) {
          callback(response.data);
        }, function(error) {
          console.error('error when calling conf services', error);
        });
    };
    var store = function(assetClass, locale, status, fromDate, toDate, callback) {
      var baseUrl = 'conf/store?assetClass=' + assetClass + '&locale=' + locale + '&status=' + status;
      if (fromDate && toDate) {
        baseUrl += '&fromDate=' + fromDate + '&toDate=' + toDate;
      }
      $extendedHttpService.post(selectedClientSrv.addToUrl(baseUrl))
        .then(function() {
          callback();
        }, function(error) {
          console.error('error when storing conf', error);
        });
    };
    var deleteUserActions = function(callback) {
      $extendedHttpService.delete('conf/do-delete-user-actions')
        .then(function() {
          callback();
        }, function() {});
    };
    var updateConfInCache = function(confUpdateVO, callback) {
      $extendedHttpService.post('conf/update/cache', confUpdateVO)
        .then(function() {
          callback();
        }, function() {});
    };

    var shouldSeeLargeRangeOfDate = function() {
      return $extendedHttpService.get(selectedClientSrv.addToUrl('conf/user-should-see-large-range-of-date'))
        .then(function(response) {
          return response.data;
        });
    };

    return {
      find: find,
      shouldSeeLargeRangeOfDate: shouldSeeLargeRangeOfDate,
      store: store,
      deleteUserActions: deleteUserActions,
      updateConfInCache: updateConfInCache
    };
  });
