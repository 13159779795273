'use strict';

angular.module('app')
  .factory('MatchingStatus', function($i18next) {
    /**
     * Calculates the css class for an matchingStatus.
     */
    var findClassForStatus = function(status) {
      if (status === 'MATCHED') {
        return 'label label-success';
      }
      if (status === 'MISMATCHED') {
        return 'label label-primary';
      }
      if (status === 'PENDING') {
        return 'label label-warning';
      }
      return 'label label-info';
    };

    /**
     * Simulates a setting of a new status from an action on a conf event
     */
    var setNewStatus = function(event, action) {
      if (action === 'REJECTION') {
        event.matchingStatus = 'MISMATCHED';
        event.matchingStatusLabel = $i18next('conf:statusMismatched');
      } else if (action === 'PREVALIDATION') {
        event.matchingStatus = 'PRE_VALIDATED';
        event.matchingStatusLabel = $i18next('conf:statusPrevalidated');
      } else {
        event.matchingStatus = 'MATCHED';
        event.matchingStatusLabel = $i18next('conf:statusMatched');
      }
    };

    return {
      findClassForStatus: findClassForStatus,
      setNewStatus: setNewStatus
    };
  })
  .factory('NotifService', function($i18next, $timeout, ezNotifications) {
    /**
     * Adds a notification on the screen for the user.
     */
    var notifAndRemoveAfterDelay = function(alertObj) {
      var notification = ezNotifications.add(alertObj);
      $timeout(function() {
        ezNotifications.remove(notification.id);
      }, 20000);
    };

    var buildSuccessMessage = function(validationType) {
      var msgKeyPrefix = 'conf:review.' + validationType;
      var successMessage = {
        title: $i18next(msgKeyPrefix + '.successTitle'),
        text: $i18next(msgKeyPrefix + '.successText'),
        importance: 'success'
      };
      return successMessage;
    };

    var buildErrorMessage = function(validationType) {
      var msgKeyPrefix = 'conf:review.' + validationType;
      var errorMessage = {
        title: $i18next(msgKeyPrefix + '.errorTitle'),
        text: $i18next(msgKeyPrefix + '.errorText'),
        importance: 'danger'
      };
      return errorMessage;
    };

    return {
      notifAndRemoveAfterDelay: notifAndRemoveAfterDelay,
      buildSuccessMessage: buildSuccessMessage,
      buildErrorMessage: buildErrorMessage
    };
  });
