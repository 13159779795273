angular.module('app')
  .directive('menubar', function() {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'menubar.html',
      scope: true,
      controller: function($scope, $location, confAssetClasses) {
        // translate asset class to route (ird, fxc, etc.)
        var translateToRouteValue = function(assetClass) {
          switch (assetClass) {
            case 'BONDS':
              return 'bonds';
            case 'FX_CASH':
              return 'fxc';
            case 'FX_OPTION':
              return 'fxo';
            case 'CTY':
              return 'cty';
            case 'EQY':
              return 'eqy';
            case 'MM':
              return 'mm';
            case 'SACS':
              return 'sacs';
            default:
              return 'ird';
          }
        };

        $scope.menuList = null;

        $scope.$on('userInfoReceived', function(event, userAssetClasses) {
          var menuList = []; // array object for ng-repeat iteration
          for (var index = 0; index < userAssetClasses.length; index++) {
            var asset = userAssetClasses[index];
            menuList[index] = {
              route: '/' + confAssetClasses.assetToFeature(asset) + '/' + asset,
              trad: 'mainLayout:navBar.' + translateToRouteValue(asset),
              href: '#/' + confAssetClasses.assetToFeature(asset) + '/' + asset
            };
          }
          $scope.menuList = menuList;
        });

        $scope.$on('clearUserInfo', function() {
          $scope.menuList = null; // Re-enable 'loading' state
        });

        $scope.isMenuSelected = function(menuRoute) {
          // equivalent to startsWith
          return ($location.path().indexOf(menuRoute, 0) === 0);
        };
        $scope.tutorialTemplateUrl = {templateUrl: '../features/quickmatch/partials/modal/tutorial-modal.html'};
      }
    };
  });
