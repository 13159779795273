angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/home', {
      templateUrl: 'home.html'
    })
      .otherwise({
        redirectTo: '/home'
      });
  })
  .controller('HomeController', function($scope, $location, $i18next, $timeout, ezNotifications, confAssetClasses) {
    $scope.fetchMenuNotFinished = true;

    $scope.redirect = function(userAssetClasses) {
      $scope.fetchMenuNotFinished = false;
      if (userAssetClasses && userAssetClasses.length > 0) {
        var defaultAsset = userAssetClasses[0];
        $location.path('/' + confAssetClasses.assetToFeature(defaultAsset) + '/' + defaultAsset);
        return;
      }
    };

    // When user comes from '/' url
    confAssetClasses.get($scope.ezweb.user.roles, false)
      .then(function(userAssetClasses) {
        $scope.redirect(userAssetClasses);
      }).catch(function() {
        var alertObj = {
          title: $i18next('common.error'),
          text: $i18next('common.errorRequestingData'),
          importance: 'danger'
        };
        var notification = ezNotifications.add(alertObj);
        $timeout(function() {
          ezNotifications.remove(notification.id);
        }, 20000);
      });
  });
